import productImg01 from "../images/products/double-sofa-01.png";
import productImg02 from "../images/products/double-sofa-02.png";
import productImg03 from "../images/products/double-sofa-03.png";

import productImg04 from "../images/products/single-sofa-01.png";
import productImg05 from "../images/products/single-sofa-02.png";
import productImg06 from "../images/products/single-sofa-03.png";
import productImg007 from "../images/products/single-sofa-04.png";

import productImg07 from "../images/products/arm-chair-01.png";
import productImg08 from "../images/products/arm-chair-02.png";
import productImg09 from "../images/products/arm-chair-03.png";
import productImg10 from "../images/products/arm-chair-01.png";

/* products images */
import sp1 from "../images/products/sp-1.png";
import sp2 from "../images/products/sp-2.png";
import sp3 from "../images/products/sp-3.png";
import sp4 from "../images/products/sp-4.png";
import sp5 from "../images/products/sp-5.png";
import sp6 from "../images/products/sp-6.png";
import sp7 from "../images/products/sp-7.png";
import sp8 from "../images/products/sp-8.png";
import sp9 from "../images/products/sp-9.png";
import sp10 from "../images/products/sp-10.png";
import sp11 from "../images/products/sp-11.png";
import sp12 from "../images/products/sp-12.png";
import sp13 from "../images/products/sp-13.png";
import sp14 from "../images/products/sp-14.png";
import sp15 from "../images/products/sp-15.png";
import sp16 from "../images/products/sp-16.png";
import sp17 from "../images/products/sp-17.png";
import sp18 from "../images/products/sp-18.png";
import sp19 from "../images/products/sp-19.png";
import sp20 from "../images/products/sp-20.png";
import sp21 from "../images/products/sp-21.png";
import sp22 from "../images/products/sp-22.png";
import sp23 from "../images/products/sp-23.png";
import sp24 from "../images/products/sp-24.png";
import sp25 from "../images/products/sp-25.png";
import sp26 from "../images/products/sp-26.png";
import sp27 from "../images/products/sp-27.png";
import sp28 from "../images/products/sp-28.png";

const productsData = [
  {
    id: "01",
    productName: "Stone and Beam Westview ",
    productName_ar: "كنبة ستون آند بيم",
    imgUrl: productImg01,
    category: "sofa",
    category_ar: "كنب",
    price: 193,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "285",
    productName: "Modern golden armchair",
    productName_ar: "كرسي بذراعين حديث - ذهبي ",
    imgUrl: sp10,
    category: "chair",
    category_ar: "كرسى",
    price: 273,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "الأصلي في الطباعة والتنضيد الإلكتروني. ",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "303",
    productName: "Dressing table, bamboo, 76x47cm",
    productName_ar: "ترابيزة  الزينة، خيزران، 76x47.5cm",
    imgUrl: sp24,
    category: "table",
    category_ar: "ترابيزة ",
    price: 109,
    shortDesc:
      "Dressing table, bamboo, 76x47 cm - Not just an ordinary dressing table!",
    shortDesc_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص.",

    description:
      "Not just an ordinary dressing table! The drawer can be accessed from both sides, so you can choose whether to place it against the wall or have it freestanding. The bamboo creates a warm, natural look.",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "الأصلي في الطباعة والتنضيد الإلكتروني. ",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "03",
    productName: "Azon Brand Modern Sofa",
    productName_ar: "أريكة حديثة ماركة أزون",
    imgUrl: productImg03,
    category: "sofa",
    category_ar: "كنب",
    price: 173,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [],
    avgRating: 0,
  },
  {
    id: "26",
    productName: "Rivet Bigelow Modern ",
    productName_ar: "كنبة حديثة من ريفيت - بيج ",
    imgUrl: productImg02,
    category: "sofa",
    category_ar: "كنب",
    price: 253,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.8,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق",
      },
      {
        rating: 4.8,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "560",
    productName: "Side table, white, 35x35cm",
    productName_ar: "طاولة جانبية، ابيض، 35x35 سم",
    imgUrl: sp23,
    category: "table",
    category_ar: "ترابيزة",
    price: 83,
    shortDesc:
      "You can use this small LACK table throughout the home and also slide it",
    shortDesc_ar:
      "أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة",
    description:
      "You can use this small LACK table throughout the home and also slide it under the larger table in the series to save space. Thanks to the unique construction, it’s easy to assemble, lift and move around.",
    description_ar:
      "ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 2.0,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          " فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد،",
      },
      {
        rating: 3.0,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشك. فلقد اتضح أن كلمات نص لوريم إيبسوم",
      },
    ],
    avgRating: 2.8,
  },
  {
    id: "260",
    productName: "Bigelow Modern bed room chair",
    productName_ar: "كرسي غرفة نوم بيجيلو حديث",
    imgUrl: sp14,
    category: "chair",
    category_ar: "كرسي",
    price: 253,
    shortDesc:
      "Bigelow Modern bed room chair Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "كرسي غرفة نوم بيجيلو حديث ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Bigelow Modern bed room chair Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      " ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",
    reviews: [
      {
        rating: 4.8,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشك. فلقد اتضح أن كلمات نص لوريم إيبسوم",
      },
      {
        rating: 4.8,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشك. فلقد اتضح أن كلمات نص لوريم إيبسوم",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "290",
    productName: "stackable, 3-legged stool with bent legs",
    productName_ar: "كرسي قابل للتكديس بثلاثة أرجل مع أرجل مثنية",
    imgUrl: sp26,
    category: "table",
    category_ar: "ترابيزة",
    price: 323,
    shortDesc:
      "stackable, 3-legged stool with bent legs illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ",
    description:
      "This stackable, 3-legged stool with bent legs and triangular-shaped seat is a great little accent that’s comes in handy when you need extra seating for guests or a place to unload your cupper.",
    description_ar:
      " ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق  (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",
    reviews: [
      {
        rating: 4.2,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "فلقد اتضح أن كلمات نص لوريم إيبسوم",
      },
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشكم",
      },
    ],
    avgRating: 4.2,
  },
  {
    id: "200",
    productName: "Lexicon 1-Piece Living Room Set",
    productName_ar: "طقم غرفة جلوس من ليكسيكون مكون من قطعة واحدة",
    imgUrl: sp15,
    category: "sofa",
    category_ar: "كنب",
    price: 323,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      " ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق  (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",
    reviews: [
      {
        rating: 3.8,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشكم",
      },
      {
        rating: 2.8,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "وخلال تتبعه لهذه الكلمة",
      },
    ],
    avgRating: 3.2,
  },
  {
    id: "204",
    productName: "Aqua Globes light",
    productName_ar: "أكوا جلوبس لايت",
    imgUrl: sp8,
    category: "decor",
    category_ar: "ديكورات",
    price: 120,
    shortDesc:
      "consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      " ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق  (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",

    reviews: [
      {
        rating: 4.2,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: " متعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت ",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: " متعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت ",
      },
    ],
    avgRating: 4.3,
  },
  {
    id: "604",
    productName: "Coffee table, white stained oak effect, 118x78 cm",
    productName_ar: "طاولة قهوة ، مظهر بلوط أبيض اللون ، 118x78 سم",
    imgUrl: sp25,
    category: "table",
    category_ar: "ترابيزة",
    price: 113,
    shortDesc:
      "Coffee table, white stained oak effect, 118x78 cm iure quas labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ",

    description:
      "The design makes this piece of furniture easy to place, easy to use for various needs, and easy to match with other furnishings.",
    description_ar:
      " ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق  (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",

    reviews: [
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "كان لوريم إيبسوم ولايزال المعيار للنص",
      },
      {
        rating: 2.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "صناعات المطابع ودور النشر.",
      },
    ],
    avgRating: 3.7,
  },
  {
    id: "04",
    productName: "Fllufy Sheep Sofa",
    productName_ar: "أريكة صغيرة الحجم",
    imgUrl: productImg04,
    category: "sofa",
    category_ar: "كنب",
    price: 163,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      " كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق  (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      " ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق  (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "صناعات المطابع ودور النشر.",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "كان لوريم إيبسوم ولايزال المعيار للنص",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "105",
    productName: "floor classic Lamp, black",
    productName_ar: "مصباح سقف كلاسيكي ، أسود",
    imgUrl: sp7,
    category: "decor",
    category_ar: "ديكورات",
    price: 103,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 3.5,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          " لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة",
      },
      {
        rating: 2.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "مضاف إليها مجموعة من الجمل النموذجية",
      },
    ],
    avgRating: 2.7,
  },
  {
    id: "555",
    productName: "Drum Storage Table",
    productName_ar: "طاولة علي شكل طبل",
    imgUrl: sp27,
    category: "table",
    category_ar: "ترابيزة ",
    price: 90,
    shortDesc:
      "Drum Storage Table Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Drum Storage Table Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 2.5,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "مضاف إليها مجموعة من الجمل النموذجية",
      },
      {
        rating: 3.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "لكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص",
      },
    ],
    avgRating: 3.2,
  },
  {
    id: "115",
    productName: "Armen Living chair",
    productName_ar: "كرسي ارمين لغرفة المعيشة",
    imgUrl: sp11,
    category: "chair",
    category_ar: "كرسي",
    price: 112,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 4.5,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "لكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص",
      },
      {
        rating: 3.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "الكلمات العشوائية إلى النص",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "05",
    productName: "Faux Velvet Sofa",
    productName_ar: "أريكة مخملية ماركة - فو",
    imgUrl: productImg05,
    category: "sofa",
    category_ar: "كنب",
    price: 163,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "الكلمات العشوائية إلى النص",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "الكلمات العشوائية إلى النص",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "06",
    productName: "Fllufy Sheep Sofa",
    productName_ar: "كنبة صغيرة ماركة - فولفو",
    imgUrl: productImg06,
    category: "sofa",
    category_ar: "كنب",
    price: 163,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "لكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "ما عبر إدخال بعض النوادر أو الكلمات العشوائية ",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "359",
    productName: "Lava Stone Table",
    productName_ar: "ترابيزة متوسطة الحجم",
    imgUrl: sp28,
    category: "table",
    category_ar: "ترابيزة ",
    price: 99,
    shortDesc:
      "Lava Stone Table consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet Lava Stone Table consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 2.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "  إدخال بعض النوادر أو الكلمات ",
      },
      {
        rating: 2.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "ما عبر إدخال بعض النوادر أو الكلمات العشوائية ",
      },
    ],
    avgRating: 2.7,
  },
  {
    id: "07",
    productName: "Sakarias Armchair",
    productName_ar: "كرسي ساكارياس",
    imgUrl: productImg07,
    category: "chair",
    category_ar: "كرسي",
    price: 99,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",

    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "الكلمات العشوائية ",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "ما عبر إدخال بعض النوادر أو الكلمات العشوائية ",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "220",
    productName: "Modern wooden chair",
    productName_ar: "كرسي خشبي حديث",
    imgUrl: sp22,
    category: "chair",
    category_ar: "كرسي",
    price: 230,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "ما عبر إدخال بعض النوادر أو الكلمات العشوائية ",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "ما عبر إدخال بعض النوادر أو الكلمات العشوائية ",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "27",
    productName: "Modern Arm Sofa",
    productName_ar: "كرسي حديث بارجل  ",
    imgUrl: productImg007,
    category: "sofa",
    category_ar: "كرسي",
    price: 173,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام ",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن  نا يوجد محتوى نصي، هنا يوجد محتوى نصي فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء. العديد من برامح النشر المكتبي وبرامح تحرير صفحات الويب تستخدم لوريم إيبسوم بشكل إفتراضي كنموذج عن النص، وإذا قمت بإدخال  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "08",
    productName: "Baltsar Chair",
    productName_ar: "كرسي بلتسر",
    imgUrl: productImg08,
    category: "chair",
    category_ar: "كرسي",
    price: 89,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "جميع مولّدات نصوص لوريم إيبسوم على الإنترنت",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة",
      },
    ],
    avgRating: 4.7,
  },

  {
    id: "09",
    productName: "Helmar Chair",
    productName_ar: "كرسي هيلمار - رمادي اسود",
    imgUrl: productImg09,
    category: "chair",
    category_ar: "كرسي",
    price: 112,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.6,
        text_ar:
          "باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة",
      },
      {
        rating: 4.9,
        text_ar: "باستخدام كلمات من قاموسة",
      },
    ],
    avgRating: 4.8,
  },
  {
    id: "252",
    productName: "Fovaa Floor Lamps for Living Room",
    productName_ar: "مصابيح أرضية لغرفة المعيشة من فوفا",
    imgUrl: sp2,
    category: "decor",
    category_ar: "ديكورات",
    price: 76,
    shortDesc:
      "Fovaa Floor Lamps for Living Room quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "باستخدام كلمات من قاموسة",
      },
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "باستخدام كلمات من قاموسة",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "232",
    productName: "Living room armchair",
    productName_ar: "كرسي غرفة المعيشة",
    imgUrl: sp5,
    category: "chair",
    category_ar: "كرسي",
    price: 312,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة",
      },
      {
        rating: 4.2,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "بأكثر من 200 كلمة لا تينية، مضاف إليها مجموعة",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "332",
    productName: "LED Desk Lamp With Goose Neck 3 Level Brightness",
    productName_ar: "كصباح مكتبي كهربائي , برقبة 3 مستويات",
    imgUrl: sp17,
    category: "decor",
    category_ar: "ديكورات",
    price: 312,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "بأكثر من 200 كلمة لا تينية، مضاف إليها مجموعة",
      },
      {
        rating: 2.2,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: " من 200 كلمة لاتينية، مضاف إليها",
      },
    ],
    avgRating: 3.1,
  },

  {
    id: "25",
    productName: "Sakarias Armchair",
    productName_ar: "كرسي ساكارياس",
    imgUrl: productImg10,
    category: "chair",
    category_ar: "كرسي",
    price: 99,
    shortDesc:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "لخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل",
      },
      {
        rating: 4.9,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن",
      },
    ],
    avgRating: 4.7,
  },
  {
    id: "150",
    productName: "General modern Wall Clock",
    productName_ar: "ساعة حائط حديثة ",
    imgUrl: sp20,
    category: "decor",
    category_ar: "ديكورات",
    price: 90,
    shortDesc:
      "amet consectetur adipisicing elit. Consequuntur iure quas illo voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن",
      },
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "صانتشر بشكل كبير في ستينيّات هذا القرن",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "226",
    productName: "modern desk chair, wooden chair",
    productName_ar: "كرسي مكتب حديث خشبي",
    imgUrl: sp12,
    category: "cair",
    category_ar: "كرسي",
    price: 320,
    shortDesc: "modern desk chair, wooden chair voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit.modern desk chair, wooden chair accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 2.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "مسة قرون من الزمن لم تقضي على هذا النص",
      },
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          " مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف",
      },
    ],
    avgRating: 3.5,
  },
  {
    id: "126",
    productName: "digital Wall Clock",
    productName_ar: "ساعة حائط رقمية",
    imgUrl: sp13,
    category: "decor",
    category_ar: "ديكورات",
    price: 120,
    shortDesc: "digital Wall Clock voluptates labore tempore!",
    shortDesc_ar:
      "هناك يوجد محتوى نصي، هنا يوجد محتوى فتجعلها تبدو (أي الأحرف) وكأنها نص مقروء.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. digital Wall Clock Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: " مطبعة مرجع شكلي لهذه الأحرف",
      },
      {
        rating: 4.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          " مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف",
      },
    ],
    avgRating: 4.5,
  },
  {
    id: "219",
    productName: "dest lamp, black",
    productName_ar: "مصباح ديست ، أسود",
    imgUrl: sp6,
    category: "decor",
    category_ar: "ديكورات",
    price: 520,
    shortDesc: "dest lamp, black voluptates labore tempore!",
    shortDesc_ar:
      "بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه.",
    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. dest lamp, black Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          " مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف",
      },
      {
        rating: 2.1,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: " متكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف",
      },
    ],
    avgRating: 3.3,
  },
  {
    id: "111",
    productName: "small modern sofa, gry",
    productName_ar: "أريكة حديثة صغيرة, رمادي",
    imgUrl: sp1,
    category: "sofa",
    category_ar: "كنب",
    price: 499,
    shortDesc: "small modern sofa, gry voluptates labore tempore!",
    shortDesc_ar:
      "بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. small modern sofa, gry Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",
    reviews: [
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: " متكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف",
      },
      {
        rating: 4.1,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "   بمثابة مرجع شكلي الأحرف",
      },
    ],
    avgRating: 4.3,
  },
  {
    id: "444",
    productName: "simple modern table",
    productName_ar: "ترابيزة صغيرة حديثة",
    imgUrl: sp21,
    category: "table",
    category_ar: "ترابيزة ",
    price: 120,
    shortDesc: "simple modern trable voluptates labore tempore!",
    shortDesc_ar:
      "بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. simple modern trable Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "   بمثابة مرجع شكلي الأحرف",
      },
      {
        rating: 4.1,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع",
      },
    ],
    avgRating: 4.3,
  },
  {
    id: "276",
    productName: "living room chair, gry",
    productName_ar: "كرسي لغرفة المعيشة , رمادي",
    imgUrl: sp16,
    category: "chair",
    category_ar: "كرسي",
    price: 520,
    shortDesc: "living room chair, gry voluptates labore tempore!",
    shortDesc_ar:
      "بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. living room chair, gry Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 3.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع",
      },
      {
        rating: 4.1,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar: "إيبسوم على الإنترنت على إعادة تكرار مقاطع",
      },
    ],
    avgRating: 4.3,
  },
  {
    id: "246",
    productName: "Dressing Chair for Living Room",
    productName_ar: "كرسي لغرفة النوم",
    imgUrl: sp9,
    category: "chair",
    category_ar: "كرسي",
    price: 622,
    shortDesc: "Dressing Chair Coffee Living Room voluptates labore tempore!",
    shortDesc_ar:
      "بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه.",

    description:
      "Lorem ispum dolor sit amet consectetur adipisicing elit. living room chair, gry Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    description_ar:
      "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.",

    reviews: [
      {
        rating: 2.6,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص مقاطع",
      },
      {
        rating: 2.1,
        text: "Lorem ispum dolor sit amet consectetur adipisicing elit.",
        text_ar:
          "أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات لوريمع",
      },
    ],
    avgRating: 2.5,
  },
];

export default productsData;
